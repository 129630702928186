//Migrated
<template lang="pug">
.d-flex.pb-5.justify-content-center.align-items-center.flex-wrap
  fa-layers.fa-5x.fa-fw
    fa(
      :icon="icons.faCloudSun",
      transform="up-13 right-17",
      style="--fa-secondary-color: #fecb21"
    )
    fa(
      :icon="icons.faUmbrellaBeach",
      transform="grow-12",
      opacity,
      style="--fa-secondary-color: #fecb21"
    )
  .d-flex.align-items-center
    .px-5(:class="{ 'error__content': !maxWidth }")
      slot
        h3.text-center(data-i18n="errorTitle") {{ $t('errorTitle') }}
        p(data-i18n="serverError") {{ $t('serverError') }}
</template>

<script>
import { faCloudSun, faUmbrellaBeach } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  props: {
    maxWidth: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      icons: {
        faCloudSun,
        faUmbrellaBeach,
      },
    }
  },
})
</script>

<style type="scss" scoped>
.fa-fw {
  width: 4em;
  height: 3em;
}

.error__content {
  max-width: 20rem;
}
</style>
